import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Post = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [boxes, setBoxes] = useState([]);

  const baseUrl = process.env.PUBLIC_URL;

  useEffect(() => {
    // Fetch post data
    fetch(baseUrl + `/json/posts.json`)
      .then(response => response.json())
      .then(data => {
        // const foundPost = data.find(post => post.id === parseInt(postId));
        const foundPost = data.find(post => post.name === postId);
        setPost(foundPost);
      
      })
      .catch(error => console.error('Error fetching post data:', error));

    // Fetch box data
    fetch(baseUrl + `/json/boxes.json`) // Assuming boxes.json is located in the public/json folder
      .then(response => response.json())
      .then(data => setBoxes(data))
      .catch(error => console.error('Error fetching box data:', error));
  }, [baseUrl,postId]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <header>
        <img src="logo.png" alt="Logo" />
      </header>
      <section>
        <img src={post.image} alt={post.title} />
      </section>
      <section>
        <div>
          {/* Main content */}
          <h1>{post.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.content }} /></div>
        <div>
          {/* Side content with boxes */}
          {boxes.map(box => (
            <div key={box.id}>
              <img src={box.image} alt={box.title} />
              <h2>{box.title}</h2>
              {/* Add link to post page */}
              <a href={box.link}>Read More</a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Post;
