// Home.js
import React, { useState, useEffect } from 'react';


const Home = () => {
  const [boxes, setBoxes] = useState([]);
  

  const baseUrl = process.env.PUBLIC_URL;
  const imageUrl = baseUrl +'/assets/img/'; 

  useEffect(() => {
   
    fetch(baseUrl + `/json/boxes.json`) 
      .then(response => response.json())
      .then(data => setBoxes(data))
      .catch(error => console.error('Error fetching box data:', error));
      console.log(boxes);
  }, [baseUrl]);

  return (
    <div>
      <header>
        <img src={imageUrl+'/header/l.png'} alt="Logo" className='ac-img-responsive ac-hedaer-logo'/>
      </header>
      <section>
        <div className='ac-container-flued'>
            <img src={imageUrl+'/home/d.jpg'} alt='Welcome to Israel' className='ac-img-responsive ac-main-img'/>
        </div> 
      </section>
      <section>
        <div className='ac-container'>
            <h1>Essential Tips for Your Israel Journey.</h1>
            <hr/>
            <p>
                Navigate Israel seamlessly with our tips and insights.<br/>
                From cultural nuances to practical travel advice, we've curated indispensable information to enhance your entire experience.<br/>
                We hope our tips here will help making your journey smoother and more rewarding. Enjoy your visit!
            </p>
            
            {boxes.map(box => (
            <div key={box.id}>
                <img src={imageUrl + 'boxes/'+box.image} alt={box.title} />
                <h2>{box.title}</h2>
                {/* Add link to post page */}
                <a href={box.link}>Read More</a>
            </div>
            ))}
        </div>
        
      </section>
    </div>
  );
};

export default Home;
