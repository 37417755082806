import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Post from './Post/Post';



const App = () => {
  return (
    <BrowserRouter>
       <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/Post/:postId" element={<Post />} />
        </Routes>
    </BrowserRouter>
  );
};

export default App;